//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import apiHelp from '@/api/apiHelp'
export default {
  name: 'Content',
  data () {
    return {
      value1: true,
      value2: true
    }
  },
  watch: {
    handler () {
      // console.log(this.content)
    }
  },
  props: ['content'],
  // 方法
  methods: {
    async getData () {
      // let id = this.$route.query.id
      // let res = await apiHelp.getContentDetails(id)
    }
  },
  created () {

  },
  mounted () {

  }
}
