//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import apiHelp from '@/api/apiHelp'

export default {
  name: 'Helpcenter',
  data () {
    return {
      menulist: [],
      uniqueopened: true,
      crumbs: '',
      content: {},
      imgUrl: 'https://jingyingbang.oss-cn-hangzhou.aliyuncs.com/',
      active: '', // 默认一级展开菜单
      openeds: '' // 二级展开菜单
    }
  },
  components: {

  },
  watch: {
    '$route.query.id' () {
      // let firstId = this.$route.query.id
      // let ids = [0, firstId]
      // this.handleSelect(firstId, ids)
      // this.active = firstId + ''
    }
  },
  // 监听
  mounted () {
  },
  // 方法
  methods: {
    ishow () {
      this.isshow = !this.isshow
    },
    // 选择子菜单，获取内容
    handleSelect (key, keyPath) {
      // console.log(keyPath)
      // console.log(keyPath[1])

      this.getChildrenData(keyPath[1])

      this.$router.replace(
        {
          path: '/content',
          query: {
            id: keyPath[1]
          }
        }
      )
    },
    // 获取一、二级菜单栏
    compare(property){
      return function(a,b){
          var value1 = a[property];
          var value2 = b[property];
          return value1 - value2;
      }
    },
    async getData () {
      let { data } = await apiHelp.getContentList()
      let list = data
      list = data.sort(this.compare('sortOrder'))
      list.forEach(el => {
        const child = el.contentList
        if(child.length > 1) {
          el.contentList = child.sort(this.compare('sortOrder'))
        }
      });
      this.menulist = list
      // console.log(data)
      // 根据地址传输过来的内容id进行查询
      let firstId = this.$route.query.id
      if (firstId == undefined) {
        // 如果没有id则默认取第一个
        firstId = this.menulist[0].contentList[0].id
      }
      let ids = [0, firstId]
      this.handleSelect(firstId, ids)
      this.active = firstId + ''
    },
    // 获取子级菜单栏内容
    async getChildrenData (key) {
      this.content = {}
      let res = await apiHelp.getContentDetails(key)
      this.content = res.data
    }
  },
  created () {
    // default-active	当前激活菜单的 index
    // default-openeds 当前打开的sub-menu的 key 数组
    // console.log(321315315)
    this.getData()
  }
}
