import { render, staticRenderFns } from "./HelpCenter2.vue?vue&type=template&id=6c9ef62a&"
import script from "./HelpCenter2.vue?vue&type=script&lang=js&"
export * from "./HelpCenter2.vue?vue&type=script&lang=js&"
import style0 from "./HelpCenter2.vue?vue&type=style&index=0&lang=scss&rel=stylesheet%2Fscss&"
import style1 from "./HelpCenter2.vue?vue&type=style&index=1&lang=scss&rel=stylesheet%2Fscss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Template: require('/devcloud/slavespace/slave1-new/workspace/j_V6eVlOYm/src/components/FilterSearch/template/index.js').default})
